<template>
  <div>
    <div class="checkout-items" v-for="product in products" :key="product.itemID">
      <b-card>
        <h3>ร้านค้า : {{product.shopName}}</h3>
        <b-card class="ecommerce-card" no-body v-for="product in product['cartProductItems']" :key="product.itemID">
          <!-- Product Image -->
          <div class="item-img">
            <b-link>
              <b-img
                :src="product.photoLink"
                :alt="`${product.shopName}-${product.itemID}`"
              />
            </b-link>
          </div>

          <!-- Product Details: Card Body -->
          <b-card-body>
            <div class="item-name">
              <h6 class="mb-0">
                <b-link class="text-body">
                  {{ product.title }}
                </b-link>
              </h6>
              <span class="item-company">By <b-link class="company-name">{{ product.shopName }}</b-link></span>

            </div>
            <span class="text-success mb-1">In Stock</span>
            <div class="item-quantity mb-1">
              <span class="quantity-title">Qty:</span>
              <b-form-spinbutton
                v-model="product.quantity"
                size="sm"
                class="ml-75 "
                inline
              />
            </div>
            <span >{{ product.prop1Text }} : {{ product.prop1Value }}</span>
            <span >{{ product.prop2Text }} : {{ product.prop2Value }}</span>
          </b-card-body>

          <!-- Product Options/Actions -->
          <div class="item-options text-center">
            <div class="item-wrapper">
              <div class="item-cost">
                <h4 class="item-price">
                  ${{ product.price }}
                </h4>
                <p
                  v-if="product.hasFreeShipping"
                  class="card-text shipping"
                >
                  <b-badge
                    pill
                    variant="light-success"
                  >
                    Free Shipping
                  </b-badge>
                </p>
              </div>
            </div>
            <b-button
              variant="light"
              class="mt-1 remove-wishlist"
              @click="removeProductFromCartClick(product)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-50"
              />
              <span>Remove</span>
            </b-button>
            <b-button
              variant="primary"
              class="btn-cart move-cart"
              @click="toggleProductInWishlist(product)"
            >
              <feather-icon
                icon="HeartIcon"
                class="mr-50"
                :class="{'fill-current': product.isInWishlist}"
              />
              <span class="text-nowrap">Wishlist</span>
            </b-button>
          </div>
        </b-card>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BLink, BImg, BButton, BBadge, BFormSpinbutton,
} from 'bootstrap-vue'
import store from '@/store'
import { ref } from '@vue/composition-api'
import { formatDate } from '@core/utils/filter'
import { useEcommerce, useEcommerceUi } from '../useEcommerce'
import { taobaoService_dotnet } from '@/views/global'
import axios from 'axios'
import { token_api, header_token } from '@/views/token'

export default {
  components: {
    BCard, BCardBody, BLink, BImg, BButton, BBadge, BFormSpinbutton,
  },
  setup() {
    const products = ref([])

    const { toggleProductInWishlist } = useEcommerceUi()
    const { removeProductFromCart } = useEcommerce()
    const removeProductFromCartClick = product => {
      removeProductFromCart(product.itemID)
        .then(() => {
          const productIndex = products.value.findIndex(p => p.itemID === product.itemID)
          products.value.splice(productIndex, 1)

          store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', products.value.length)
        })
    }

    // const fetchCartProducts = () => {
    //   store.dispatch('app-ecommerce/fetchCartProducts')
    //     .then(response => {
    //       products.value = response.data.products
    //       console.log("mockup: ",response.data.products);
    //     })
    // }
    // fetchCartProducts()

    const  getProduct = async () =>{
      var axios = require('axios');
      var data = JSON.stringify({
        "CustomerID": 1,
      });

      var config = {
        method: 'post',
        url: taobaoService_dotnet + 'app/cart/load-cart-page',
        headers: {
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIwIiwibmFtZSI6InRhb2JhbzJ5b3UiLCJyb2xlIjoiYWRtaW4iLCJjdXN0b21lcmlkIjoiIiwiZXhwIjoxNjI2MTAyNzgwLCJpc3MiOiJ0YW9iYW8yeW91IiwiYXVkIjoiaHR0cHM6Ly93d3cudGFvYmFvMnlvdS5jb20ifQ.gIhTvIMLXxpq6bUFPbp242szDgPRl33SaF3k5f6T3-U', 
          'Content-Type': 'application/json'
        },
        data : data
      };

      axios(config)
      .then(function (response) {
        // console.log("resfull: ",response.data);
        // console.log("cartProducts: ",response.data.cartProducts);
        products.value = response.data.cartProducts
        console.log("product: ", products);
        console.log("value: ", products.value);
      })
      .catch(function (error) {
        console.log(error);
      });
    }
    getProduct()

    return {
      products,

      // UI
      toggleProductInWishlist,
      removeProductFromCartClick,

      // Filter
      formatDate,
    }
  },

}
</script>

<style>

</style>
